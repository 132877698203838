import react, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import customFilterMethod from "helpers/customFilterMethod";
import ReactTable from "react-table-v6";


export default function RevendeurListIntoUserTab({importedData, navigation}) {
    const nav = useNavigate();
    const columns = [
      {
        Header: () => <div style={{ textAlign: "center" }}>Entreprise</div>,
        accessor: (d) => d.company,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>CONTACT</div>,
        accessor: (d) => `${d.name}  ${d.firstname}`,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>PAYS</div>,
        accessor: (d) => d.country,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>STATUS</div>,
        accessor: (d) => d.state,
        Cell: ({ value }) => (
          <div
            style={{ color: value === 1 ? "green" : "red", textAlign: "center" }}
          >
            {value === 1 ? "actif" : "inactif"}
          </div>
        ),
      },
    ].map((col, i) => {
      col.id = `${i}`;
      return col;
    });

    const onRowClick = (state, rowInfo, column, instance) => {
        return {
          onClick: (e) => {
            nav(`/showResel/${rowInfo.original.id}`);
          },
        };
      };
 return (
   
      <>
      {importedData && importedData.length > 0 ? (
        <ReactTable
          filterable
          defaultFilterMethod={customFilterMethod}
          getTrProps={onRowClick}
          data={importedData}
          columns={columns}
          defaultPageSize={10}
          previousText={navigation.previousText}
          nextText={navigation.nextText}
          pageText={navigation.pageText}
          ofText={navigation.ofText}
          rowsText={navigation.rowsText}
        />
      ) : (
        <p>Aucune donnée disponible.</p>
      )}
      </>
        )
}