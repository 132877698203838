import React from 'react';
import { Link } from 'react-router-dom';
import "../../../assets/css/custom.css";


const MaintenancePage = () => {
  return (
    <div id="maintenance-container">
      <i className="fas fa-water water-icon"></i>
      <h1>Site en maintenance planifié</h1>
      <p>Toute l'équipe technique d'Acquaprocess est mobilisée afin de rétablir le service dans les plus brefs délais.</p>
      {/* <Link to="/admin-login">Connexion Administrateur</Link> */}
    </div>
  );
};

export default MaintenancePage;