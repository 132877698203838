import react, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "../../../../assets/css/custom.css";
import customFilterMethod from "helpers/customFilterMethod.js";
import ClientListIntoUserTab from "../../../components/ClientListIntoUserTab";
import { API_URL, getApiConfig } from "../../Config/apiConfig.js";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

export default function ShowTechnicienTab() {


  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const params = useParams();
 
  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

  const [clientBelongTo, setClientBelongTo] = useState("");
  const [clients, setClients] = useState([]);
  const [techniciens, setTechniciens] = useState([]);
  const [revendeurs, setRevendeurs] = useState([]);
  const [distributeurs, setDistributeurs] = useState([]);

useEffect(() => {
  axios
    .get(API_URL + `showChildListBelongToParent/${params.id}`, getApiConfig())
    .then((res) => {
      const clientList = [];
      const technicienList = [];
      const revendeurList = [];
      const distributeurList = [];

      res.data.forEach((item) => {
        switch (item.role_id) {
          case 5:
            clientList.push(item);
            break;
          case 4:
            technicienList.push(item);
            break;
          case 3:
            revendeurList.push(item);
            break;
          case 2:
            distributeurList.push(item);
            break;
          default:
            console.log("Role non reconnu : ", item);
            break;
        }
      });

      setClients(clientList);
      setTechniciens(technicienList);
      setRevendeurs(revendeurList);
      setDistributeurs(distributeurList);
      
      console.log("Clients: ", clientList);
      console.log("Techniciens: ", technicienList);
      console.log("Revendeurs: ", revendeurList);
      console.log("Distributeurs: ", distributeurList);
    })
    .catch((err) => console.error(err));
}, [params.id]);

  const addParamToUrl = (event) => {
    nav("/showReseller/" + event.currentTarget.id);
  };
  
  return (
    <>
      { (clients && clients.length > 0) && 
              <>
                <Row className="CenterAll">
                  <div className="containertitle">
                    <h2 className="titlePage">LISTE CLIENT</h2>
                  </div>
                </Row>
                <Row className="CenterAll">
                  <Col lg="12">
                    <Card className="mt-5">
                      <Col lg="12" className="d-flex justify-content-center">
                        <Card>
                          <CardBody>
                              <ClientListIntoUserTab importedData={clients} navigation ={localization}/>
                          </CardBody>
                        </Card>
                      </Col>
                    </Card>
                  </Col>
                </Row>
                </>
                }
    </>
  );
}