import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  Col,
} from "reactstrap";
import { CircleLoader } from "react-spinners";
import { OLD_API_URL, oldGetApiConfig } from "../Config/oldApiConfig";
import moment from "moment";

export default function TabChart() {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState("6m");
  const [capteur, setCapteur] = useState([]);
  const [capteurData, setCapteurData] = useState([]);
  const [selectedFormule, setSelectedFormule] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [valueType, setValueType] = useState(null);
  const [timeFilter, setTimeFilter] = useState("day");
  const [boitierMesure, setBoitierMesure] = useState([]);
  const [mesureIndex, setMesureIndex] = useState([]);
  const [capteurIndice, setCapteurIndice] = useState(null);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "area",
      height: 350,
      width: "100%",
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
    },
    xaxis: {
      type: "datetime",
      min: new Date().getTime() - getTimeRangeMillis(timeRange),
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        colorStops: [
          {
            offset: 0,
            color: "#00E396",
            opacity: 0.7,
          },
          {
            offset: 100,
            color: "#0092FF",
            opacity: 0.9,
          },
        ],
      },
    },
  });
  const params = useParams();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggle2 = () => setDropdownOpen2((prevState) => !prevState);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  const handleCustomDateRange = () => {
    setTimeFilter("custom");
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchBoitierMesure = await axios.get(
          OLD_API_URL + `boitier/${params.id}/mesure?q=${timeFilter}`,
          oldGetApiConfig()
        );
        setBoitierMesure(fetchBoitierMesure.data.data);

        const boitierImport = await axios.get(
          OLD_API_URL + `boitier/${params.id}/capteur`,
          oldGetApiConfig()
        );
        if (boitierImport.data) {
          setCapteur(boitierImport.data);
        }
        console.log("Capteur:", capteur);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [params, timeFilter]);

  useEffect(() => {
    if (Array.isArray(boitierMesure) && boitierMesure.length > 0 && capteurIndice !== null) {
      const filteredMesure = boitierMesure.filter(element => element.indice === capteurIndice);
      setMesureIndex(filteredMesure);
    }
  }, [boitierMesure, capteurIndice]);

  useEffect(() => {
    let typeCapteur;
    if (mesureIndex.length > 0 && valueType !== null) {
          setLoading(true)
      const updatedChartData = mesureIndex.map((mesure) => {
        let Capteur_by_indice;
        for (let i = 0; i < 7; i++) {
          if (capteur[i] !== undefined) {
            Capteur_by_indice = capteur[i];
            if (Capteur_by_indice.pivot.indice === capteurIndice) {
              typeCapteur = Capteur_by_indice.type;
              console.log("TypeCapteur:", typeCapteur);
            } 
          }
        }
        const value = mesure.valeur !== 0 ? applyFormula(mesure.valeur, valueType, typeCapteur) : 0;
        return { x: moment(mesure.created_at, "DD/MM/YYYY HH:mm:ss").valueOf(), y: value };
      });

      const value = updatedChartData.length > 0 ? updatedChartData[0].y : 0;
      if (isNaN(value)) {
        console.error("Invalid value detected:", value);
        return { x: new Date().getTime(), y: 0 };
      }
      setLoading(false)

      setChartData(updatedChartData);
    }
  }, [mesureIndex, valueType]);

  useEffect(() => {
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        min: new Date().getTime() - getTimeRangeMillis(timeFilter),
      },
    }));
  }, [timeFilter]);

  const applyFormula = (value, index, type) => {
    const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

    switch (index) {
      case 0:
        return roundToTwoDecimals(value);
      case 1:
        if (capteurIndice === 7) {
          let temp = ((value * 3.3 / 4096) - 0.5) / 0.01;
          return roundToTwoDecimals(temp);
        } else {
          let mA = 0.000005 * (value * value) + 0.0014 * value + 2.742;
          return roundToTwoDecimals(mA);
        }
      default:
        return roundToTwoDecimals(0);
    }
  };

  function getTimeRangeMillis(range) {
    const ranges = {
      "1h": 3600000,
      "24h": 86400000,
      "1w": 604800000,
      "1m": 2592000000,
      "6m": 15552000000,
      "1y": 31536000000,
    };
    return ranges[range] || 86400000;
  }

  const capteurList = {
    1: "Capteur_1",
    2: "Capteur_2",
    3: "Capteur_3",
    4: "Capteur_4",
    5: "Capteur_5",
    6: "Capteur_6",
    7: "Interne",
  };

  useEffect(() => {
    if (capteurIndice === null && Object.keys(capteurList).length > 0) {
      setCapteurIndice(parseInt(Object.keys(capteurList)[0]));
    }
  }, [capteurList, capteurIndice]);

  useEffect(() => {
    if (timeFilter === "custom" && startDate && endDate) {
      async function fetchCustomData() {
        try {      
          setLoading(true)
          const fetchBoitierMesure = await axios.get(
            `${OLD_API_URL}boitier/${params.id}/mesure?q=custom&from=${startDate.toISOString()}&to=${endDate.toISOString()}`,
            oldGetApiConfig()
          );
          setBoitierMesure(fetchBoitierMesure.data.data);
        } catch (error) {
          console.error("Erreur lors de la récupération des données ! :", error);
        } finally {
          setLoading(false);
        }
      }

      fetchCustomData();
    }
  }, [timeFilter, startDate, endDate, params]);

  return (
    <Col>
      {/* <div className="btn-bar-chart">
        <Button color="primary" onClick={() => setTimeFilter("today")}>Aujourd'hui</Button>
      </div> */}
      <div className="btn-bar-chart">
        <label className="date-label">Date de début : </label>
        <input type="date" value={startDate.toISOString().split("T")[0]} onChange={handleStartDateChange} />
        <label className="date-label">Date de fin : </label>
        <input type="date" value={endDate.toISOString().split("T")[0]} onChange={handleEndDateChange} />
        <Button color="primary" onClick={handleCustomDateRange}>Appliquer</Button>
      </div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret>
          {capteurIndice !== undefined && capteurIndice !== null
            ? `${capteurList[capteurIndice]}`
            : "Sélectionnez un capteur"}
        </DropdownToggle>
        <DropdownMenu>
          {Object.entries(capteurList).map(([key, label]) => (
            <DropdownItem key={key} onClick={() => setCapteurIndice(parseInt(key))}>
              {`${label}`}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {capteurIndice !== undefined ? (
        <Dropdown isOpen={dropdownOpen2} toggle={toggle2}>
          <DropdownToggle caret>
            {valueType !== null
              ? `Unité de mesure: ${valueType}`
              : "Sélectionnez une unité de mesure"}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem key={0} onClick={() => setValueType(0)}>
              {`Unité de mesure : numérique`}
            </DropdownItem>
            <DropdownItem key={1} onClick={() => setValueType(1)}>
              {`Unité de mesure : température`}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : null}

      {loading ? (
        <CircleLoader color={"#3B90C8"} />
      ) : (
        <Card className="chart-container">
          <Chart
            series={[
              {
                name: capteurList[capteurIndice],
                data: chartData,
              },
            ]}
            options={{
              ...chartOptions,
              xaxis: {
                ...chartOptions.xaxis,
                min: new Date().setHours(0, 0, 0, 0),
                max: new Date().getTime(),
              },
            }}
            type="area"
            width="100%"
            height="450px"
          />
        </Card>
      )}
    </Col>
  );
}
