import react, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-table-v6/react-table.css";
import ReactTable from "react-table-v6";
import "../../../../assets/css/custom.css";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { getApiConfig, API_URL } from "views/composant/Config/apiConfig";
import customFilterMethod from "helpers/customFilterMethod";
import TechListIntoUserTab from "../../../components/TechListIntoUserTab";
import ClientListIntoUserTab from "../../../components/ClientListIntoUserTab";
import RevendeurListIntoUserTab from "../../../components/RevendeurListIntoUserTab";
export default function ShowDistributeurTab() {
  const nav = useNavigate();

  const params = useParams();

  //DATA
  const [resellerList, setResellerList] = useState("");
  const [clients, setClients] = useState([]);
  const [techniciens, setTechniciens] = useState([]);
  const [revendeurs, setRevendeurs] = useState([]);
  const [distributeurs, setDistributeurs] = useState([]);
  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

useEffect(() => {
  axios
    .get(API_URL + `showChildListBelongToParent/${params.id}`, getApiConfig())
    .then((res) => {
     const testInfo = [
    {
        "id": 83,
        "company": null,
        "name": "Alice",
        "firstname": "Dave",
        "country": "Canada",
        "state": 1,
        "role_id": 2
    },
    {
        "id": 47,
        "company": null,
        "name": "John",
        "firstname": "John",
        "country": null,
        "state": 0,
        "role_id": 5
    },
    {
        "id": 6,
        "company": null,
        "name": "Alice",
        "firstname": "Dave",
        "country": null,
        "state": 1,
        "role_id": 3
    },
    {
        "id": 75,
        "company": "TechCorp",
        "name": "John",
        "firstname": "Jane",
        "country": "France",
        "state": 1,
        "role_id": 4
    },
    {
        "id": 18,
        "company": null,
        "name": "Bob",
        "firstname": "John",
        "country": null,
        "state": 0,
        "role_id": 3
    },
    {
        "id": 65,
        "company": "NextGen",
        "name": "Sophia",
        "firstname": "Alice",
        "country": "USA",
        "state": 0,
        "role_id": 4
    },
    {
        "id": 92,
        "company": null,
        "name": "Eve",
        "firstname": "Sophia",
        "country": "Canada",
        "state": 0,
        "role_id": 3
    },
    {
        "id": 40,
        "company": null,
        "name": "Dave",
        "firstname": "Charlie",
        "country": null,
        "state": 0,
        "role_id": 5
    }];

      const clientList = [];
      const technicienList = [];
      const revendeurList = [];
      const distributeurList = [];

      res.data.forEach((item) => {
        switch (item.role_id) {
          case 5:
            clientList.push(item);
            break;
          case 4:
            technicienList.push(item);
            break;
          case 3:
            revendeurList.push(item);
            break;
          case 2:
            distributeurList.push(item);
            break;
          default:
            console.log("Role non reconnu : ", item);
            break;
        }
      });

      setClients(clientList);
      setTechniciens(technicienList);
      setRevendeurs(revendeurList);
      setDistributeurs(distributeurList);
      
      console.log("Clients: ", clientList);
      console.log("Techniciens: ", technicienList);
      console.log("Revendeurs: ", revendeurList);
      console.log("Distributeurs: ", distributeurList);
    })
    .catch((err) => console.error(err));
}, [params.id]);

  return (
    <>
     { (revendeurs && revendeurs.length > 0) && 
        <>
          <Row className="CenterAll">
            <div className="containertitle">
              <h2 className="titlePage">LISTE REVENDEUR</h2>
            </div>
          </Row>
          <Row className="CenterAll">
            <Col lg="12">
              <Card className="mt-5">
                <Col lg="12" className="d-flex justify-content-center">
                  <Card>
                    <CardBody>                      
                        <RevendeurListIntoUserTab importedData={revendeurs} navigation ={localization}/>
                    </CardBody>
                  </Card>
                </Col>
              </Card>
            </Col>
          </Row>
          </>
          }
    { (clients && clients.length > 0) && 
        <>
          <Row className="CenterAll">
            <div className="containertitle">
              <h2 className="titlePage">LISTE CLIENT</h2>
            </div>
          </Row>
          <Row className="CenterAll">
            <Col lg="12">
              <Card className="mt-5">
                <Col lg="12" className="d-flex justify-content-center">
                  <Card>
                    <CardBody>
                        <ClientListIntoUserTab importedData={clients} navigation ={localization}/>
                    </CardBody>
                  </Card>
                </Col>
              </Card>
            </Col>
          </Row>
          </>
          }
      { (techniciens && techniciens.length > 0) && 
          <>
            <Row className="CenterAll">
              <div className="containertitle">
                <h2 className="titlePage">LISTE TECHNICIEN</h2>
              </div>
            </Row>
            <Row className="CenterAll">
              <Col lg="12">
                <Card className="mt-5">
                  <Col lg="12" className="d-flex justify-content-center">
                    <Card>
                      <CardBody>
                        <TechListIntoUserTab importedData={techniciens} navigation ={localization}/>
                      </CardBody>
                    </Card>
                  </Col>
                </Card>
              </Col>
            </Row>
            </>
      }
      </>
  );
}