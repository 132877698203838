import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Map from "../../Map/map.js";
import "../../../../assets/css/custom.css";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { CircleLoader } from "react-spinners";
import Autocomplete from "react-google-autocomplete";
import { getApiConfig, getUserRoleId } from "../../Config/apiConfig.js";
import {
  OLD_API_URL,
  oldGetApiConfig,
} from "views/composant/Config/oldApiConfig.js";

export default function ShowClientHeader() {
  const userRoleId = getUserRoleId();
  const nav = useNavigate();

  const [formVisible, setFormVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  };

  const [assignVisible, setAssignVisible] = useState(false);
  const [assignSubmitted, setAssignSubmitted] = useState(false);

  const handleToggleAssign = () => {
    setAssignVisible(!assignVisible);
  };

  const [apiKey, setApiKey] = useState(
    "AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk"
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  //modal system
  const [modalComment, setModalComment] = React.useState(false);
  const toggleModalComment = () => {
    setModalComment(!modalComment);
  };
  const [dropdownOpenForGuarantee, setDropdownOpenForGuarantee] =
    useState(false);
  const toggleDropGuarantee = () =>
    setDropdownOpenForGuarantee((prevState) => !prevState);
  const [dropdownOpenForState, setDropdownOpenForState] = useState(false);
  const toggleDropState = () =>
    setDropdownOpenForState((prevState) => !prevState);

  //TOKEN
  const token = localStorage.getItem("token");

  const params = useParams();

  //API URL
  const API_URL = "https://acquaback.shenron.dev/api/";

  //ALERT
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  //AXIOS CONFIGURATION
  let CONFIG_ = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  //DATAset
  const [comment, setComment] = useState("");
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [siret, setSiret] = useState(0);
  const [tva, setTVA] = useState(0);
  const [state, setState] = useState("");
  const [markerLat, setMarkerLat] = useState("");
  const [markerLng, setMarkerLng] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [resellerName, setResellerName] = useState("");
  const [user_id, setUser_id] = useState("");
  const [status, setStatus] = useState("");
  const [guarantee, setGuarantee] = useState(null);
  const [GR, setGR] = useState(null);
  const [streetNumber, setStreetNumber] = useState("")


    const [resellerList, setResellerList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [isEditingReseller, setIsEditingReseller] = useState(false); // Contrôle de la visibilité
//OLD reseller
const [oldResellerId, setOldResellerId] = useState(null)

  useEffect(() => {
    setStatus(state === "actif" ? 1 : 0);
    setGR(guarantee === "actif" ? 1 : 0);
  }, [state, guarantee]);

  //oldapiPut
  /*const updateOldApi = async (
    params,
    oldResellerId,
    company,
    name,
    firstname,
    email,
    phone1,
    phone2,
    website,
    siret,
    tva,
    streetNumber,
    address,
    city,
    postcode,
    country,
    markerLat,
    markerLng,
    status,
    comment,
    GR
  ) => {
    let Oldguarantee = GR == "1";
    let oldApiPayload = {
      id: parseInt(params.id),
      nom: company,
      contratMaintenance: Oldguarantee,
      contact: {
        id: params.id,
        nom: name,
        prenom: firstname,
        loginEmail: email,
        email: email,
        tel1: phone1,
        tel2: phone2,
        siteInternet: website,
        siret: siret,
        numTva: tva,
      },
      adresse: {
        streetNumber: streetNumber,
        route: address,
        locality: city,
        postalCode: postcode,
        country: country,
        location: {
          lat: parseFloat(markerLat),
          lng: parseFloat(markerLng),
        },
        viewport: {
          south: parseFloat(markerLat) - 0.001,
          west: parseFloat(markerLng) - 0.001,
          north: parseFloat(markerLat) + 0.001,
          east: parseFloat(markerLng) + 0.001,
        },
      },
      revendeur: oldResellerId, //Voir si cette ligne et bien donnée est bien mis ici quand on assigne un revendeur, FAIRE LA VERIF
      statut: status === 1,
      commentaire: comment,
    };

    try {
      const oldApiUpdateResponse = await axios.put(
        OLD_API_URL + `client/${params.id}`,
        oldApiPayload,
        oldGetApiConfig()
      );

      if (oldApiUpdateResponse.data === "success") {
        console.log("Old API updated successfully");
      } else {
        console.warn(
          "Unexpected response from Old API update:",
          oldApiUpdateResponse.data
        );
      }
    } catch (error) {
      console.error("Error updating Old API:", error.message);
    }
  };*/


  // SAVOIR SI JE DOIS METTRE DANS LE PUT TOUTES LES DONNEES OU JUSTE LID DU REVENDEUR EN PAYLOAD
/*const ResellerInOldApiForAssign = (email) => {
  axios
    .get(OLD_API_URL + `revendeur`, oldGetApiConfig())
    .then((response) => {
      console.log(response, 'listerevendeurOLD puis email a trouver : ', email)
      const resellers = response.data?.data || [];
      const matchingReseller = resellers.find(
        (reseller) => reseller.contact.loginEmail && reseller.contact.loginEmail === email
      );

      if (matchingReseller?.id) {
        setOldResellerId(matchingReseller.id)
        console.log("Email correspondant trouvé :", matchingReseller);
      } else {
        console.log("Aucun email correspondant trouvé.");
      }
    })
    .catch((error) => {
      console.error("Erreur lors de la requête :", error);
    });
};*/


  const handleSubmitFor = async (e) => {
    e.preventDefault();

    let newApiPayload = {
      comment: comment,
      company: company,
      name: name,
      firstname: firstname,
      markerLat: markerLat,
      markerLng: markerLng,
      address: address,
      city: city,
      postcode: postcode,
      country: country,
      phone1: phone1,
      phone2: phone2,
      email: email,
      website: website,
      siret: siret,
      tva: tva,
      state: status,
      maintenance: GR,
    };

    try {
      // First, try to get the client from the old API
      // Check if the client exists in the old API
      let oldApiClientExists = false;
      try {
        const oldApiResponse = await axios.get(
          OLD_API_URL + `client/${params.id}`,
          oldGetApiConfig()
        );
        oldApiClientExists = oldApiResponse.data === "success";
      } catch (error) {
        console.log(
          "Client not found in old API or unauthorized access:",
          error.message
        );
      }

      // Update old API if the client exists
      /*
      if (oldApiClientExists) {
        await updateOldApi(
          params,
          setOldResellerId,
          company,
          name,
          firstname,
          email,
          phone1,
          phone2,
          website,
          siret,
          tva,
          streetNumber,
          address,
          city,
          postcode,
          country,
          markerLat,
          markerLng,
          status,
          comment,
          GR
        );
      } else {
        console.error("ERROR CLIENT DOESNT EXIST");
      } */

      // Now, update the new API
      const newApiResponse = await axios.post(
        API_URL + `updateUserInfo/${params.id}`,
        newApiPayload,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (newApiResponse.data === "success") {
        setAlertContent(
          <Alert color="success">Les données ont bien été enregistrées !</Alert>
        );
      } else {
        setAlertContent(
          <Alert color="warning">
            Mise à jour partielle : les données ont été mises à jour dans la
            nouvelle API, mais pas dans l'ancienne.
          </Alert>
        );
      }
    } catch (error) {
      console.error("Error updating APIs:", error.message);
      setAlertContent(
        <Alert color="danger">
          Une erreur est survenue lors de la mise à jour des APIs :{" "}
          {error.message}
        </Alert>
      );
    }

    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 5000);

    setFormSubmitted(false);
    setFormVisible(false);
  };
  const handleSubmitForAssignedParent = async (e) => {
    e.preventDefault();

    try {
      /*await updateOldApi(
        params,
        oldResellerId,
        company,
        name,
        firstname,
        email,
        phone1,
        phone2,
        website,
        siret,
        tva,
        streetNumber,
        address,
        city,
        postcode,
        country,
        markerLat,
        markerLng,
        status,
        comment,
        GR
      ); */

      const newApiResponse = await axios.post(
        API_URL + `updateParentName/${params.id}`,
        { user_id: user_id },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (newApiResponse.data === "success") {
        setAlertContent(
          <Alert color="success">Les données ont bien été enregistrées !</Alert>
        );
        setAlert(true);
        setTimeout(() => setAlert(false), 3000);
      } else {
        setAlertContent(
          <Alert color="danger">
            Une erreur est survenue, veuillez réessayer
          </Alert>
        );
        setAlert(true);
        setTimeout(() => setAlert(false), 3000);
      }
    } catch (error) {
      console.error("Error updating Old API or new API:", error.message);
      setAlertContent(
        <Alert color="danger">
          Une erreur est survenue lors de la mise à jour des APIs :{" "}
          {error.message}
        </Alert>
      );
      setAlert(true);
      setTimeout(() => setAlert(false), 3000);
    }

    setAssignSubmitted(false);
    setAssignVisible(false);
  };

  let showResellerList = axios.get(
    API_URL + "showResellerListForAssign",
    getApiConfig()
  );
  console.log(showResellerList, "showReselllist")

  let showUserRequest = axios.get(API_URL + `showUser/${params.id}`, CONFIG_);
  console.log(showUserRequest, "showUserRequest");
  useEffect(() => {
    axios
      .all([showUserRequest, showResellerList])
      .then(
        axios.spread(function (res1, res2) {
          setComment(res1.data?.user.comment);
          setCompany(res1.data?.user.company);
          setName(res1.data?.user.name.toUpperCase());
          setFirstname(res1.data?.user.firstname.toUpperCase());
          setPhone1(res1.data?.user.phone1);
          setPhone2(res1.data?.user.phone2);
          setEmail(res1.data?.user.email);
          setWebsite(res1.data?.user.website);
          setSiret(res1.data?.user.siret || 0);
          setTVA(res1.data?.user.tva || 0);
          setState(res1.data?.user.state);
          setMarkerLat(res1.data?.user.markerLat);
          setMarkerLng(res1.data?.user.markerLng);
          setAddress(res1.data?.user.address);
          setCity(res1.data?.user.city);
          setPostcode(res1.data?.user.postCode);
          setResellerName(res1.data?.parent?.company);
          console.log('tomatooo')
          console.log(res2.data, "resellerList")
          setResellerList(res2.data);
          setGuarantee(res1.data?.user.guarantee);
        })
      )
      .catch((err) => {  console.error("Erreur dans Axios :", err);});
    // eslint-disable-next-line
  }, []);




  useEffect(() => {
    if (assignVisible == true && searchTerm.trim() == "" ) {
      setSearchResults(resellerList);
    } else {
    const results = resellerList.filter(
      (reseller) =>
        reseller.company &&
        reseller.company.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }
  }, [searchTerm, resellerList, assignVisible]);
  

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectReseller = (reseller) => {
    setSelectedReseller(reseller);
    setUser_id(reseller.id);
    setSearchResults([]);
    setSearchTerm(`${reseller.company}`);
    setResellerName(`${reseller.company}`);
    console.log(reseller, "resellerinformations")
    //ResellerInOldApiForAssign(reseller.email) ON commente tout ce qui a un lien avec oldAPI
  };

  const fullAddress = `${address}, ${city}, ${postcode}, ${country}`;

  useEffect(() => {
    const geocoder = new window.google.maps.Geocoder({
      apiKey: apiKey,
    });
    geocoder.geocode({ address: fullAddress }, (results, status) => {
      if (status === "OK") {
        const Ulocation = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        setMarkerLat(Ulocation.lat);
        setMarkerLng(Ulocation.lng);
      }
    });
    // eslint-disable-next-line
  }, [fullAddress]);

  const location = { lat: markerLat, lng: markerLng };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10%",
          right: "10%",
          left: "20%",
          zIndex: "999",
        }}
      >
        {alert ? <div>{alertContent}</div> : <></>}
      </div>

      <Col lg="12" className="noPad">
        <Row className="CenterAll">
          <div className="containertitle">
            <h2 className="titlePage">INFORMATIONS CLIENT</h2>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className="CenterAll">
              <Col lg="12" className="mt-4 noPad">
                <Row>
                  <Col lg="5" md="12" className="noPad">
                    {!formVisible || formSubmitted ? (
                      <div>
                        <Card className="cardContainer">
                          <CardBody>
                            <div className="containerTextCard">
                              <h4 className="titleCard ">
                                INFORMATIONS GENERALES
                              </h4>
                              <div
                                style={{
                                  borderTop: "0.5px solid #fff",
                                  marginBottom: "20px",
                                }}
                              ></div>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Entreprise</div>
                                  <div className="textRow">{company}</div>
                                </Row>
                              </Card>

                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Prénom & Nom</div>
                                  <div className="textRow">{name}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Téléphone 1</div>
                                  <div className="textRow">{phone1}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Téléphone 2</div>
                                  <div className="textRow">{phone2}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">E-mail</div>
                                  <div className="textRow">{email}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Site Internet</div>
                                  <div className="textRow">{website}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">SIRET</div>
                                  <div className="textRow">{siret}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">TVA</div>
                                  <div className="textRow">{tva}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Status</div>
                                  <div className="textRow">{state}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Contrat garantie
                                  </div>
                                  <div className="textRow">{guarantee}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Adresse</div>
                                  <div className="textRow">
                                    {address} , {postcode} {city} {country}
                                  </div>
                                </Row>
                              </Card>
                            </div>
                            <div className="text-center">
                              <Button
                                color="primary"
                                onClick={handleToggleForm}
                              >
                                Editer
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    ) : (
                      <div>
                        <form onSubmit={handleSubmitFor}>
                          <Card className="cardContainer">
                            <CardBody>
                              <div className="containerTextCard">
                                <h4 className="titleCard ">
                                  EDITER LES INFORMATIONS UTILISATEURS
                                </h4>
                                <div
                                  style={{
                                    borderTop: "0.5px solid #fff",
                                    marginBottom: "20px",
                                  }}
                                ></div>
                                <Card className="rowContainer">
                                  <div className="input-container-row-custom">
                                    <div className="titleRow">Entreprise</div>
                                    <div className="textRow">
                                      <Input
                                        value={company}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setCompany(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Card>

                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Prénom & Nom</div>
                                    <div className="textRow">
                                      <Input
                                        value={firstname}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row-contact"
                                        onChange={(e) =>
                                          setFirstname(e.target.value)
                                        }
                                      />
                                      <Input
                                        value={name}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row-contact"
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Téléphone 1</div>
                                    <div className="textRow">
                                      <Input
                                        value={phone1}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setPhone1(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Téléphone 2</div>
                                    <div className="textRow">
                                      <Input
                                        value={phone2}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setPhone2(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">E-mail</div>
                                    <div className="textRow">
                                      <Input
                                        value={email}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Site Internet
                                    </div>
                                    <div className="textRow">
                                      <Input
                                        value={website}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setWebsite(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">SIRET</div>
                                    <div className="textRow">
                                      <Input
                                        value={siret}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setSiret(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">TVA</div>
                                    <div className="textRow">
                                      <Input
                                        value={tva}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) => setTVA(e.target.value)}
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                {(getUserRoleId() !== 5 ||
                                  getUserRoleId !== 4) && (
                                  <Card className="rowContainer">
                                    <Row>
                                      <div className="titleRow">Status</div>
                                      <div className="textRow">
                                        <Dropdown
                                          isOpen={dropdownOpenForState}
                                          toggle={toggleDropState}
                                        >
                                          <DropdownToggle className="dropdown-boitier-custom">
                                            {state}
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-custom">
                                            <DropdownItem
                                              style={{
                                                backgroundColor: "#9bc4de",
                                                color: "white",
                                              }}
                                              className="text-center"
                                              value={"actif"}
                                              onClick={(e) =>
                                                setState(e.target.value)
                                              }
                                            >
                                              actif
                                            </DropdownItem>
                                            <DropdownItem
                                              style={{
                                                backgroundColor: "#9bc4de",
                                                color: "white",
                                              }}
                                              className="text-center"
                                              value={"inactif"}
                                              onClick={(e) =>
                                                setState(e.target.value)
                                              }
                                            >
                                              inactif
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </div>
                                    </Row>
                                  </Card>
                                )}
                                {/* {getUserRoleId() === "1" && (
                                  <Card className="rowContainer">
                                    <Row>
                                      <div className="titleRow">
                                        Contrat Maintenace
                                      </div>
                                      <div className="textRow">
                                        <Dropdown
                                          isOpen={dropdownOpenForGuarantee}
                                          toggle={toggleDropGuarantee}
                                        >
                                          <DropdownToggle className="dropdown-boitier-custom">
                                            {guarantee}
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-custom">
                                            <DropdownItem
                                              style={{
                                                backgroundColor: "#9bc4de",
                                                color: "white",
                                              }}
                                              className="text-center"
                                              value={"actif"}
                                              onClick={(e) =>
                                                setGuarantee(e.target.value)
                                              }
                                            >
                                              actif
                                            </DropdownItem>
                                            <DropdownItem
                                              style={{
                                                backgroundColor: "#9bc4de",
                                                color: "white",
                                              }}
                                              className="text-center"
                                              value={"inactif"}
                                              onClick={(e) =>
                                                setGuarantee(e.target.value)
                                              }
                                            >
                                              inactif
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </div>
                                    </Row>
                                  </Card>
                                )} */}
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Adresse</div>
                                    <div className="textRow">
                                      <Autocomplete
                                        apiKey={
                                          "AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk&libraries=places"
                                        }
                                        className="heighTextArea pl-3 form-control"
                                        id="custom-input-row"
                                        onPlaceSelected={(place) => {
                                          setAddress(
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] === "route"
                                            ).long_name +
                                              (place.address_components.find(
                                                (component) =>
                                                  component.types[0] ===
                                                  "street_number"
                                              )
                                                ? " " +
                                                  place.address_components.find(
                                                    (component) =>
                                                      component.types[0] ===
                                                      "street_number"
                                                  ).long_name
                                                : "")
                                          );
                                          const postalCodeComponent =
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] ===
                                                "postal_code"
                                            );
                                          const streetNumberComponent =
                                            place.address_components.find(
                                              (component) =>
                                                component.types.includes(
                                                  "street_number"
                                                )
                                            );

                                          if (streetNumberComponent) {
                                            setStreetNumber(
                                              streetNumberComponent.long_name
                                            );
                                          } else {
                                            console.warn(
                                              "Aucun street_number trouvé pour cette adresse."
                                            );
                                          }

                                          if (postalCodeComponent) {
                                            setPostcode(
                                              postalCodeComponent.long_name
                                            );
                                          }
                                          setCity(
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] ===
                                                "locality"
                                            ).long_name
                                          );
                                          setCountry(
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] === "country"
                                            ).long_name
                                          );
                                        }}
                                        options={{
                                          types: ["address"],
                                          componentRestrictions: {
                                            country: ["fr", "it"],
                                          },
                                        }}
                                      />
                                    </div>
                                  </Row>
                                </Card>
                              </div>
                              <div className="text-center">
                                <Button type="submit" color="primary">
                                  Enregistrer
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        </form>
                      </div>
                    )}
                    {!assignVisible || assignSubmitted ? (
                      <div>
                        <Card className="cardContainer">
                          <CardBody>
                            <div className="containerTextCard">
                              <h4 className="titleCard ">REVENDEUR ASSIGNE</h4>
                              <div
                                style={{
                                  borderTop: "0.5px solid #fff",
                                  marginBottom: "20px",
                                }}
                              ></div>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Revendeur</div>
                                  <div className="textRow">{resellerName}</div>
                                </Row>
                              </Card>
                            </div>
                            {(userRoleId === "1" || userRoleId === "2") && (
                              <div className="text-center">
                                <Button
                                  color="primary"
                                  onClick={handleToggleAssign}
                                >
                                  Editer
                                </Button>
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      </div>
                    ) : (
                      <div>
                        <form onSubmit={handleSubmitForAssignedParent}>
                          <Card className="cardContainer">
                            <CardBody>
                              <div className="containerTextCard">
                                <h4 className="titleCard">REVENDEUR ASSIGNE</h4>
                                <div
                                  style={{
                                    borderTop: "0.5px solid #fff",
                                    marginBottom: "20px",
                                  }}
                                ></div>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Revendeur</div>
                                    <div className="textRow">
                                      <input
                                        className=""
                                        id="custom-input-row-noheight"
                                        autoComplete="off"
                                        type="search"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                      />
                                      {assignVisible && (
                                        <div
                                          className="custom-search-result"
                                          onClick={() => setSearchResults([])}
                                        >
                                          <ul>
                                            {searchResults.map((reseller) => (
                                              <li
                                                key={reseller.id}
                                                className={
                                                  selectedReseller &&
                                                  selectedReseller.id ===
                                                    reseller.id
                                                    ? "selected"
                                                    : ""
                                                }
                                                onClick={() =>
                                                  handleSelectReseller(reseller)
                                                }
                                              >
                                                {reseller.company}{" "}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </Row>
                                </Card>
                              </div>
                              <div className="text-center">
                                <Button type="submit" color="primary">
                                  Enregistrer
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        </form>
                      </div>
                    )}
                  </Col>
                  <Col lg="7" md="6" className="noPad">
                    <Card>
                      {/* <GoogleMaps /> */}
                      <CardBody className="mapContainer rightContainer">
                        <div
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            height: "400px",
                          }}
                        >
                          {loading ? (
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircleLoader color={"#3B90C8"} />
                            </div>
                          ) : (
                            <Map
                              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk&libraries=places`}
                              loadingElement={
                                <div
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <CircleLoader color={"red"} />
                                </div>
                              }
                              containerElement={
                                <div style={{ height: `100%` }} />
                              }
                              mapElement={<div style={{ height: `100%` }} />}
                              location={location}
                            />
                          )}
                        </div>
                      </CardBody>
                      <div className="text-center">
                        <CardBody className="rightContainer">
                          <Row className="d-flex justify-content-between mr-4 ml-3">
                            <h4 className="titleCardContrast">
                              Informations complémentaires
                            </h4>
                            <div
                              style={{
                                borderTop: "0.5px solid #fff",
                                marginBottom: "20px",
                              }}
                            ></div>
                            <Button
                              className="btn-round btn-icon "
                              color="primary"
                              onClick={toggleModalComment}
                            >
                              <i className="tim-icons icon-simple-add text-white" />
                            </Button>
                          </Row>
                          <div className="mt-4 pb-3 mr-4 ml-4"> {comment}</div>
                        </CardBody>
                      </div>
                      {/* MODAL COMMENT */}
                      <Modal
                        isOpen={modalComment}
                        toggle={toggleModalComment}
                        size="lg"
                      >
                        <ModalHeader
                          className="justify-content-center"
                          toggle={toggleModalComment}
                        >
                          <h4>Ajouter des informations complémentaires</h4>
                        </ModalHeader>
                        <ModalBody>
                          <form onSubmit={handleSubmitFor}>
                            <Input
                              value={comment}
                              className="heighTextArea pl-3"
                              type="textarea"
                              cols="50"
                              rows="15"
                              name="text"
                              id="exampleText"
                              placeholder="Informations complémentaires..."
                              onChange={(e) => setComment(e.target.value)}
                            />
                            <Button
                              className="float-right mt-3 mr-5"
                              type="submit"
                              color="primary"
                              onClick={toggleModalComment}
                            >
                              Ajouter
                            </Button>
                          </form>
                        </ModalBody>
                      </Modal>
                      {/* END MODAL COMMENT */}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
