import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminLogin = ({ setUserRole }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const API_URL = "https://acquaback.shenron.dev/api/";
  const CONFIG_ = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        API_URL + "login",
        {
          email: username,
          password,
        },
        CONFIG_
      );

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user_params_id", response.data.user.id);
      localStorage.setItem("user_role_id", response.data.user.role_id);
      localStorage.setItem("loggedUser", JSON.stringify(response.data.user));

      const userRoleId = response.data.user.role_id;
      setUserRole(userRoleId);

      if (response.data.user.password_changed === 1) {
        navigate("/Dashboard");
      } else {
        navigate("/resetPassword");
      }

      // Fetch old token
      const oldTokenResponse = await fetch("https://acquagest-api.acquaprocess.eu/api/login", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: username,
          password,
          device_name: "react-spa",
        }),
      });

      if (!oldTokenResponse.ok) {
        throw new Error(`Erreur de requête: ${oldTokenResponse.status}`);
      }

      const oldTokenData = await oldTokenResponse.json();
      localStorage.setItem("oldtoken", oldTokenData.token);
      console.log("Old token attribué:", oldTokenData.token);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Email ou mot de passe incorrect");
      } else {
        console.error("Erreur lors de la requête:", error.message);
      }
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h1>Connexion Administrateur</h1>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Nom d'utilisateur"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <button type="submit">Se connecter en tant qu'administrateur</button>
      </form>
    </div>
  );
};

export default AdminLogin;