import React, { useState, useEffect } from "react";
// import moment from 'moment';
import Datetime from "react-datetime";
import axios from "axios";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "../../../assets/css/custom.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Alert } from "reactstrap";
import Autocomplete from "react-google-autocomplete";
import ShowBoitierMap from "./showBoitierMap.js";
import ShowOldBoitierAssign from "./showOldBoitierAssign.js";
import ShowBoitierComment from "./showBoitierComment.js";
import ShowOldBoitierComment from "./ShowOldBoitierComment";
import ShowOldBoitierImage from "./showOldBoitierImage.js";
import { API_URL, getUserRoleId, getApiConfig } from "../Config/apiConfig.js";
import {
  OLD_API_URL,
  oldGetApiConfig,
  getOldMultipartConfig,
  oldGetUserRoleId,
  oldGetUserId,
} from "../Config/oldApiConfig";
/* old import */
import OldBoitierLastAlert from "./oldBoitierLastAlert";
import moment from "moment";

export default function ShowBoitierHeader() {
  const userRoleId = getUserRoleId();

  const nav = useNavigate();
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [formVisible, setFormVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  };

  const [dropdownOpenForVersion1, setDropdownOpenForVersion1] = useState(false);
  const [dropdownOpenForVersion2, setDropdownOpenForVersion2] = useState(false);

  const toggleDropVersion1 = () => {
    setDropdownOpenForVersion1((prevState) => !prevState);
  };

  const toggleDropVersion2 = () => {
    setDropdownOpenForVersion2((prevState) => !prevState);
  };
  const [dropdownOpenForLeasing, setDropdownOpenForLeasing] = useState(false);
  const toggleDropLeasing = () =>
    setDropdownOpenForLeasing((prevState) => !prevState);
  const [dropdownOpenForGuarantee, setDropdownOpenForGuarantee] =
    useState(false);
  const toggleDropGuarantee = () =>
    setDropdownOpenForGuarantee((prevState) => !prevState);
  const [dropdownOpenForBoost, setDropdownOpenForBoost] = useState(false);
  const toggleDropBoost = () =>
    setDropdownOpenForBoost((prevState) => !prevState);
  const [dropdownOpenForState, setDropdownOpenForState] = useState(false);
  const toggleDropState = () =>
    setDropdownOpenForState((prevState) => !prevState);

  const [dropdownOpenForGenA, setDropdownOpenForGenA] = useState(false);

  const toggleDropGenA = () =>
    setDropdownOpenForGenA((prevState) => !prevState);
  const [dropdownOpenForGenB, setDropdownOpenForGenB] = useState(false);
  const toggleDropGenB = () =>
    setDropdownOpenForGenB((prevState) => !prevState);
  const [dropdownOpenForCoTime, setDropdownOpenForCoTime] = useState(false);

  const [dropdownOpenForLang, setDropdownOpenForLang] = useState(false);
  const toggleDropLang = () =>
    setDropdownOpenForLang((prevState) => !prevState);

  const toggleDropCoTime = () =>
    setDropdownOpenForCoTime((prevState) => !prevState);

  const params = useParams();

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const [boitier_id, setBoitier_id] = useState("");
  const [comment, setComment] = useState("");
  const [rapportId, setRapportId] = useState("");

  const [oldComment, setOldComment] = useState("");
  const [markerLat, setMarkerLat] = useState("");
  const [markerLng, setMarkerLng] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [versionInstalle, setVersionInstalle] = useState("");
  const [versionVisible, setVersionVisible] = useState("");
  const [modeBoost, setModeBoost] = useState("");
  const [connectionTimeLimit, setConnectionTimeLimit] = useState("");
  const [state, setState] = useState("");
  const [generatorStateA, setGeneratorStateA] = useState("");
  const [generatorStateB, setGeneratorStateB] = useState("");
  const [langue, setLangue] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [user_id, setUser_id] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [adresseID, setAdresseID] = useState("");
  const [fullAddressValue, setFullAddressValue] = useState("");
  const [dateActivation, setDateActivation] = useState(
    moment().format("DD/MM/YYYY")
  );
  const [formattedDate, setFormattedDate] = useState(
    moment().format("DD/MM/YYYY")
  );
  const [customerId, setCustomerId] = useState("");
  const [allClients, setAllClients] = useState([]);
  // A SUPP OU GARDER EN COMMENT ?
  //   const [leasing, setLeasing] = useState("");
  //   const [dateLeasing, setDateLeasing] = useState("");
  //   const [guarantee, setGuarantee] = useState("");
  const [dateGuarantee, setDateGuarantee] = useState("");
  const [previousLSValue, setPreviousLSValue] = useState("");
  const [previousGRValue, setPreviousGRValue] = useState("");

  const [MB, setMB] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const [GNA, setGNA] = useState("");
  const [GNB, setGNB] = useState("");
  const [LG, setLG] = useState("");

  const [CTL, setCTL] = useState("");

  const [constantesAttr, setConstantesAttr] = useState(null);
  // console.log(constantesAttr, "constantesAttr");
  // A SUPP OU GARDER EN COMMENT ?
  //   const [LS, setLS] = useState("");
  //   const [GR, setGR] = useState("");

  function convertToBoolean(value) {
    return value === 1;
  }
  const connectionTimeLimitMap = {
    "sans délais": 0,
    "10 jours": 1,
    "30 jours": 2,
    "90 jours": 3,
    "180 jours": 4,
  };
  // A SUPP OU GARDER EN COMMENT ?
  //   const leasingMap = {
  //     "12 mois": 1,
  //     "24 mois": 2,
  //     "36 mois": 3,
  //     "48 mois": 4,
  //     "pas de contract": 5,
  //   };
  const guaranteeMap = {
    "12 mois": 1,
    "24 mois": 2,
    "36 mois": 3,
    "48 mois": 4,
  };

  useEffect(() => {
    // console.log("languo,", langue);
    setMB(modeBoost === "actif" ? 1 : 0);
    setSTATUS(state === "actif" ? 1 : 0);
    setGNA(generatorStateA === "actif" ? 1 : 0);
    setGNB(generatorStateB === "actif" ? 1 : 0);
    setCTL(connectionTimeLimitMap[connectionTimeLimit] || 0);
    setLG(
      langue === "Francais"
        ? 0
        : langue === "Italien"
        ? 2
        : langue === "Anglais"
        ? 3
        : langue === "Espagnol"
        ? 4
        : 0
    );

    // A SUPP OU GARDER EN COMMENT ?
    // setLS(leasingMap[leasing] || leasingMap.default);
    // setGR(guaranteeMap[guarantee] || guaranteeMap.default);
  }, [
    langue,
    modeBoost,
    state,
    generatorStateA,
    generatorStateB,
    connectionTimeLimit,
    connectionTimeLimitMap,
  ]);

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    fetchAllClients();
  }, []);

  const fetchAllClients = async () => {
    try {
      const response = await axios.get(
        OLD_API_URL + "client",
        oldGetApiConfig()
      );
      if (response.data && response.data.data) {
        setAllClients(response.data.data);
        // console.log(response.data.data, "Liste Clients");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des clients:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          OLD_API_URL + `boitier/${params.id}`,
          oldGetApiConfig()
        );
        setImageUrl(response.data.data.rapport.photo?.[0]?.name);
        // console.log(
        //   response.data.data.rapport.photo?.[0]?.name,
        //   "setImageUrl(response.data.data.rapport.photo?.[0]?.name);"
        // );
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, [params.id]);

  useEffect(() => {
    if (dateActivation && moment(dateActivation, "DD/MM/YYYY").isValid()) {
      setFormattedDate(
        moment(dateActivation, "DD/MM/YYYY").format("DD/MM/YYYY")
      );
    }
  }, [dateActivation]);

  const fullAddress = `${address}, ${city}, ${postcode}, ${country}`;

  const handleSubmitFor = async (e) => {
    e.preventDefault();
    if (!selectedSuggestion) {
      setAlertContent(
        <Alert color="danger">
          Veuillez sélectionner une adresse autocomplété !
        </Alert>
      );
      setAlert(true);
      const timeId = setTimeout(() => {
        setAlert(false);
      }, 3000);
      return;
    }
    if (!customerId) {
      setAlertContent(
        <Alert color="danger">Veuillez sélectionner un client valide</Alert>
      );
      setAlert(true);
      setTimeout(() => setAlert(false), 3000);
      return;
    }
    await geocodeAddress(fullAddress);
    let oldPayload = {
      noSerie: constantesAttr.noSerie,
      client: customerId,
      adresse: {
        id: adresseID,
        streetNumber: streetNumber,
        route: address,
        locality: city,
        administrativeAreaLevel1:
          constantesAttr.adresseAdministrativeAreaLevel1,
        administrativeAreaLevel2:
          constantesAttr.adresseAdministrativeAreaLevel2,
        postalCode: postcode,
        country: country,
        location: {
          lat: markerLat,
          lng: markerLng,
        },
        viewport: {
          south: constantesAttr.south,
          west: constantesAttr.west,
          north: constantesAttr.north,
          east: constantesAttr.east,
        },
      },
      versionInstalle: versionInstalle,
      versionVisible: versionVisible,
      statut: convertToBoolean(STATUS),
      modeBoost: convertToBoolean(MB),

      dateActivation: dateActivation,

      generateura: convertToBoolean(GNA),
      generateurb: convertToBoolean(GNB),
      langue: LG,
      commentaire: comment,

      connectionLimitTime: CTL,
      diagnostic: constantesAttr.diagnostic,
    };
    console.log(oldPayload, "oldPayload");
    oldPayload.dateActivation = moment(dateActivation, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    // A SUPP OU GARDER EN COMMENT ?
    // if (LS !== previousLSValue) {
    //   payload.leasing = LS;
    // }
    // if (GR !== previousGRValue) {
    //   payload.guarantee = GR;
    // }

    // A CONVERTIR AVEC OLD API A LA PALCE DE NEW API

    axios
      .put(OLD_API_URL + `boitier/${params.id}`, oldPayload, oldGetApiConfig())
      .then(function (response) {
        if (response.data === "success") {
          setAlertContent(
            <Alert color="success">Les données ont bien été enregistré !</Alert>
          );
          setAlert(true);
          const timeId = setTimeout(() => {
            setAlert(false);
          }, 3000);
        } else {
          setAlertContent(
            <Alert color="danger">
              Une erreur est survenue, veuillez réessayer
            </Alert>
          );
          setAlert(true);
          const timeId = setTimeout(() => {
            setAlert(false);
          }, 3000);
        }
      });
    setFormSubmitted(false);
    setFormVisible(false);
  }; // WARNING !!! WARNING !!!!! ce code doit etre converti en old API !!! WARINING ! WARNING !

  // Reponse shared by Header
  const location = useLocation();
  const isOld = location.state?.isOld;
  const idBoitier = location.state?.id;

  // AXIOS GET REQUEST a convertir en old API ??

  //   let showContractRequest = axios.get(
  //     API_URL + `showContract/${params.id}`,
  //     getApiConfig()
  //   );

  // SETTER OLD
  const [showOldBoitierRequest, setShowOldBoitierRequest] = useState([]);

  // console.log("render");
  // OLD API
  useEffect(() => {
    // console.log("ueEffect1");

    let oldShowBoitierRequest = axios
      .get(OLD_API_URL + `boitier/${params.id}`, oldGetApiConfig())
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(
          "Une erreur s'est produite lors de la récupération des données de l'API ancienne:",
          err
        );
        throw err;
      }, []);
    axios.all([oldShowBoitierRequest]).then(
      axios.spread(function (oldBoitierResponse) {
        const oldBoitierData = oldBoitierResponse.data;

        // console.log("showBoitierRequest", oldBoitierData);
        setDateActivation(
          moment(oldBoitierData?.dateActivation).format("DD/MM/YYYY")
        ); // Assurez-vous que ces lignes sont présentes et correctes
        setLangue(
          oldBoitierData?.langue === 0
            ? "Français"
            : oldBoitierData?.langue === 2
            ? "Italien"
            : oldBoitierData?.langue === 3
            ? "Anglais"
            : oldBoitierData?.langue === 4
            ? "Espagnol"
            : "Français"
        );

        setBoitier_id(oldBoitierData?.id);
        setComment(oldBoitierData?.rapport?.commentaire);
        setRapportId(oldBoitierData?.rapport?.id);
        setAddress(oldBoitierData?.adresse?.route);
        setCity(oldBoitierData?.adresse?.locality);
        setPostcode(oldBoitierData?.adresse?.postalCode);
        setCountry(oldBoitierData?.adresse?.country);
        setModeBoost(oldBoitierData?.modeBoost === true ? "actif" : "inactif");
        setVersionInstalle(oldBoitierData?.versionInstalle);
        setVersionVisible(oldBoitierData?.versionVisible);
        setStreetNumber(oldBoitierData?.adresse?.streetNumber);
        setState(oldBoitierData?.statut === true ? "actif" : "inactif");
        setGeneratorStateA(
          oldBoitierData?.generateura === true ? "actif" : "inactif"
        );
        setGeneratorStateB(
          oldBoitierData?.generateurb === true ? "actif" : "inactif"
        );
        setCustomerName(oldBoitierData?.client?.nom);
        setUser_id(oldBoitierData?.client?.id);
        setMarkerLat(oldBoitierData?.adresse?.location?.lat);
        setMarkerLng(oldBoitierData?.adresse?.location?.lng);

        setAdresseID(oldBoitierData?.adresse?.id);
        setFullAddressValue(
          `${oldBoitierData?.adresse?.route}, ${oldBoitierData?.adresse?.postalCode} ${oldBoitierData?.adresse?.locality}, ${oldBoitierData?.adresse?.country}`
        );
        setConnectionTimeLimit(
          oldBoitierData?.connectionLimitTime === 0
            ? "Sans délais"
            : oldBoitierData?.connectionLimitTime === 1
            ? "10 jours"
            : oldBoitierData?.connectionLimitTime === 2
            ? "30 jours"
            : oldBoitierData?.connectionLimitTime === 3
            ? "90 jours"
            : oldBoitierData?.connectionLimitTime === 4
            ? "180 jours"
            : "Sans délais"
        );
        setCustomerId(oldBoitierData?.client?.id);

        // Move constantesAttr inside useEffect
        setConstantesAttr({
          noSerie: oldBoitierData?.noSerie,
          client: oldBoitierData?.client,
          adresseAdministrativeAreaLevel1:
            oldBoitierData?.adresse?.administrativeAreaLevel1,
          adresseAdministrativeAreaLevel2:
            oldBoitierData?.adresse?.administrativeAreaLevel2,
          south: oldBoitierData?.adresse?.viewport?.south,
          west: oldBoitierData?.adresse?.viewport?.west,
          north: oldBoitierData?.adresse?.viewport?.north,
          east: oldBoitierData?.adresse?.viewport?.east,
          bobineA: oldBoitierData?.bobinea === true ? "actif" : "inactif",
          bobineB: oldBoitierData?.bobineb === true ? "actif" : "inactif",

          diagnostic: oldBoitierData?.diagnostic,
        });
      })
    );
  }, [params.id]);

  const geocodeAddress = async (fullAddress) => {
    try {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: fullAddress }, (results, status) => {
        if (status === "OK") {
          const Ulocation = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          console.log("Geocoding success:", Ulocation);
          setMarkerLat(Ulocation.lat);
          setMarkerLng(Ulocation.lng);
        } else {
          console.error("Geocoding error:", status);
        }
      });
    } catch (error) {
      console.error("Geocoding error:", error);
    }
  };

  const shouldDisplay = address || postcode || city || country;

  function formatDates(activeDate, mesures) {
    return mesures.map((mes) => {
      const date = moment(mes.created_at, "DD/MM/YYYY HH:mm:ss");
      switch (activeDate) {
        case "year":
          return date.format("MMM");
        case "day":
          return date.format("HH");
        default:
          return date.format("DD/MM");
      }
    });
  }
  const [selectedSuggestion, setSelectedSuggestion] = useState(true);

  const handleCustomerBlur = () => {
    const foundClient = allClients.find(
      (client) => client.nom.toLowerCase() === customerName.toLowerCase()
    );

    if (foundClient) {
      setCustomerId(foundClient.id);
    } else {
      setCustomerId("");
      setAlertContent(<Alert color="warning">Client non trouvé</Alert>);
      setAlert(true);
      setTimeout(() => setAlert(false), 3000);
    }
  };

  const [suggestions, setSuggestions] = useState([]);

  const handleCustomerInputChange = (e) => {
    const value = e.target.value;
    setCustomerName(value);
    console.log(value, "valeur dans inputChange")
    console.log("allClients", allClients);
    if (value.length === 0) {
      // Afficher toutes les suggestions si le champ est vide
      setSuggestions(allClients);
    } else {
      // Filtrer les suggestions basées sur la saisie partielle

      const matchingSuggestions = allClients.filter((client) => {
  const nom = client.nom?.toLowerCase(); // Vérifie que client.nom n'est pas null ou undefined
  if (!nom) return false; // Si nom est null ou vide, exclut ce client
  return (
    nom.includes(value.toLowerCase()) ||
    nom.split(" ").some((word) => word.startsWith(value.toLowerCase()))
  );
});// Limite à 5 suggestions
      setSuggestions(matchingSuggestions);
    }

    // Réinitialiser l'ID du client si le champ est vidé
    if (value === "") {
      setCustomerId("");
    }
  };

  const handleSuggestionClick = (client) => {
    setCustomerName(client.nom);
    setCustomerId(client.id);
    setSuggestions([]);
  };

  const handleInputFocus = () => {
    // Afficher toutes les suggestions au focus
    console.log(allClients, "allclient dans inputfocus")
    setSuggestions(allClients);
  };
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10%",
          right: "10%",
          left: "20%",
          zIndex: "999",
        }}
      >
        {alert ? <div>{alertContent}</div> : <></>}
      </div>

      <Col className="noPad" lg="12">
        <Row className="CenterAll">
          <div className="containertitle">
            <h2 className="titlePage">INFORMATIONS GENERALES</h2>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className="CenterAll">
              <Col lg="12" className="mt-4 noPad">
                <Row>
                  <Col lg="5" md="6" className="noPad ">
                    {!formVisible || formSubmitted ? (
                      <div>
                        <Card className="cardContainer">
                          <CardBody>
                            <div className="containerTextCard noMarg">
                              <h4 className="titleCard ">
                                BOITIER N° {constantesAttr?.noSerie}
                              </h4>
                              <div
                                style={{
                                  borderTop: "0.5px solid #fff",
                                  marginBottom: "20px",
                                }}
                              ></div>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Date d'activation
                                  </div>
                                  <div className="textRow">{formattedDate}</div>
                                </Row>
                              </Card>
                              {userRoleId === "1" && (
                                <>
                                  <Card className="rowContainer">
                                    <Row>
                                      <div className="titleRow">
                                        Version embarquée
                                      </div>
                                      <div className="textRow">
                                        {`3.${versionInstalle}`}
                                      </div>
                                    </Row>
                                  </Card>
                                </>
                              )}
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Version
                                  </div>
                                  <div className="textRow">
                                    {`3.${versionVisible}`}
                                  </div>
                                </Row>
                              </Card>
                              {
                                // A SUPP OU GARDER EN COMMENT ?
                                /* <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Contrat leasing
                                  </div>
                                  <div className="textRow">{leasing}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Expiration leasing
                                  </div>
                                  <div className="textRow">{dateLeasing}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Contrat garantie
                                  </div>
                                  <div className="textRow">{guarantee}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Expiration garantie
                                  </div>
                                  <div className="textRow">{dateGuarantee}</div>
                                </Row>
                              </Card> */
                              }

                              {userRoleId === "1" && (
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Mode boost</div>
                                    <div className="textRow">{modeBoost}</div>
                                  </Row>
                                </Card>
                              )}
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Etat du boitier
                                  </div>
                                  <div className="textRow">{state}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Etat générateur A
                                  </div>
                                  <div className="textRow">
                                    {generatorStateA}
                                  </div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Etat générateur B
                                  </div>
                                  <div className="textRow">
                                    {generatorStateB}
                                  </div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Etat bobine A</div>
                                  <div className="textRow">
                                    {constantesAttr?.bobineA}
                                  </div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Etat bobine B</div>
                                  <div className="textRow">
                                    {constantesAttr?.bobineB}
                                  </div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Langue boitier</div>
                                  <div className="textRow">{langue}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Délai sans connexion
                                  </div>
                                  <div className="textRow">
                                    {connectionTimeLimit}
                                  </div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Emplacement du boitier
                                  </div>
                                  <div className="textRow">
                                    {shouldDisplay
                                      ? (address ? `${address}, ` : "") +
                                        [postcode, city, country]
                                          .filter(Boolean)
                                          .join(" ")
                                      : ""}
                                  </div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Client Assigné</div>
                                  <div className="textRow">{customerName}</div>
                                </Row>
                              </Card>
                            </div>
                            {userRoleId != 5 && (
                              <div className="text-center">
                                <Button
                                  color="primary"
                                  onClick={handleToggleForm}
                                >
                                  Editer
                                </Button>
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      </div>
                    ) : (
                      <div>
                        <form onSubmit={handleSubmitFor}>
                          <Card className="cardContainer">
                            <CardBody>
                              <div className="containerTextCard">
                                <h4 className="titleCard">
                                  BOITIER N° {constantesAttr?.noSerie}
                                </h4>
                                <div
                                  style={{
                                    borderTop: "0.5px solid #fff",
                                    marginBottom: "20px",
                                  }}
                                ></div>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Date d'activation
                                    </div>
                                    <div className="textRow">
                                      {/* <div className="btn btn-light" onClick={() => setShowDatePicker(true)}>
                                                    {moment(dateActivation).format('DD/MM/YYYY')}
                                                </div> */}

                                      {/* {showDatePicker && ( */}
                                      {/* // <div className="date-picker-overlay"> */}
                                      <Datetime
                                        value={moment(
                                          dateActivation,
                                          "DD/MM/YYYY"
                                        )}
                                        onChange={(value) => {
                                          if (moment(value).isValid()) {
                                            setDateActivation(
                                              value.format("DD/MM/YYYY")
                                            );
                                            setFormattedDate(
                                              value.format("DD/MM/YYYY")
                                            );
                                          }
                                        }}
                                        inputProps={{
                                          readOnly: true,
                                          style: {
                                            cursor: "pointer",
                                          },
                                        }}
                                        className="dropdown-boitier-custom"
                                        dateFormat="DD/MM/YYYY"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                      />
                                      {/* // </div> */}
                                      {/* )} */}
                                    </div>
                                  </Row>
                                </Card>
                                {userRoleId === "1" && (
                                  <>
                                    <Card className="rowContainer">
                                      <Row>
                                        <div className="titleRow">
                                          Version embarquée
                                        </div>
                                        <div className="textRow">
                                          <Dropdown
                                            isOpen={dropdownOpenForVersion2}
                                            toggle={toggleDropVersion2}
                                          >
                                            <DropdownToggle className="dropdown-boitier-custom">
                                              {`3.${versionInstalle}`}
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-custom">
                                              <DropdownItem
                                                style={{
                                                  backgroundColor: "#9bc4de",
                                                  color: "white",
                                                }}
                                                className="text-center"
                                                value={"1"}
                                                onClick={(e) =>
                                                  setVersionInstalle(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                3.1
                                              </DropdownItem>
                                              <DropdownItem
                                                style={{
                                                  backgroundColor: "#9bc4de",
                                                  color: "white",
                                                }}
                                                className="text-center"
                                                value={"2"}
                                                onClick={(e) =>
                                                  setVersionInstalle(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                3.2
                                              </DropdownItem>
                                              <DropdownItem
                                                style={{
                                                  backgroundColor: "#9bc4de",
                                                  color: "white",
                                                }}
                                                className="text-center"
                                                value={"3"}
                                                onClick={(e) =>
                                                  setVersionInstalle(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                3.3
                                              </DropdownItem>
                                              <DropdownItem
                                                style={{
                                                  backgroundColor: "#9bc4de",
                                                  color: "white",
                                                }}
                                                className="text-center"
                                                value={"4"}
                                                onClick={(e) =>
                                                  setVersionInstalle(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                3.4
                                              </DropdownItem>
                                              <DropdownItem
                                                style={{
                                                  backgroundColor: "#9bc4de",
                                                  color: "white",
                                                }}
                                                className="text-center"
                                                value={"5"}
                                                onClick={(e) =>
                                                  setVersionInstalle(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                3.5
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>
                                      </Row>
                                    </Card>
                                  </>
                                )}
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Version affichée
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForVersion1}
                                        toggle={toggleDropVersion1}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {`3.${versionVisible}`}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"1"}
                                            onClick={(e) =>
                                              setVersionVisible(e.target.value)
                                            }
                                          >
                                            3.1
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"2"}
                                            onClick={(e) =>
                                              setVersionVisible(e.target.value)
                                            }
                                          >
                                            3.2
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"3"}
                                            onClick={(e) =>
                                              setVersionVisible(e.target.value)
                                            }
                                          >
                                            3.3
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"4"}
                                            onClick={(e) =>
                                              setVersionVisible(e.target.value)
                                            }
                                          >
                                            3.4
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"5"}
                                            onClick={(e) =>
                                              setVersionVisible(e.target.value)
                                            }
                                          >
                                            3.5
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                {}
                                {userRoleId === "1" && (
                                  <Card className="rowContainer">
                                    <Row>
                                      <div className="titleRow">Mode boost</div>
                                      <div className="textRow">
                                        <Dropdown
                                          isOpen={dropdownOpenForBoost}
                                          toggle={toggleDropBoost}
                                        >
                                          <DropdownToggle className="dropdown-boitier-custom">
                                            {modeBoost}
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-custom">
                                            <DropdownItem
                                              style={{
                                                backgroundColor: "#9bc4de",
                                                color: "white",
                                              }}
                                              className="text-center"
                                              value={"actif"}
                                              onClick={(e) =>
                                                setModeBoost(e.target.value)
                                              }
                                            >
                                              actif
                                            </DropdownItem>
                                            <DropdownItem
                                              style={{
                                                backgroundColor: "#9bc4de",
                                                color: "white",
                                              }}
                                              className="text-center"
                                              value={"inactif"}
                                              onClick={(e) =>
                                                setModeBoost(e.target.value)
                                              }
                                            >
                                              inactif
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </div>
                                    </Row>
                                  </Card>
                                )}
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Etat du boitier
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForState}
                                        toggle={toggleDropState}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {state}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"actif"}
                                            onClick={(e) =>
                                              setState(e.target.value)
                                            }
                                          >
                                            actif
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"inactif"}
                                            onClick={(e) =>
                                              setState(e.target.value)
                                            }
                                          >
                                            inactif
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Etat générateur A
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForGenA}
                                        toggle={toggleDropGenA}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {generatorStateA}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"actif"}
                                            onClick={(e) =>
                                              setGeneratorStateA(e.target.value)
                                            }
                                          >
                                            actif
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"inactif"}
                                            onClick={(e) =>
                                              setGeneratorStateA(e.target.value)
                                            }
                                          >
                                            inactif
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Etat générateur B
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForGenB}
                                        toggle={toggleDropGenB}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {generatorStateB}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"actif"}
                                            onClick={(e) =>
                                              setGeneratorStateB(e.target.value)
                                            }
                                          >
                                            actif
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"inactif"}
                                            onClick={(e) =>
                                              setGeneratorStateB(e.target.value)
                                            }
                                          >
                                            inactif
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Langue </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForLang}
                                        toggle={toggleDropLang}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {langue}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"Francais"}
                                            onClick={(e) =>
                                              setLangue(e.target.value)
                                            }
                                          >
                                            Francais
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"Italien"}
                                            onClick={(e) =>
                                              setLangue(e.target.value)
                                            }
                                          >
                                            Italien
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"Anglais"}
                                            onClick={(e) =>
                                              setLangue(e.target.value)
                                            }
                                          >
                                            Anglais
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"Espagnol"}
                                            onClick={(e) =>
                                              setLangue(e.target.value)
                                            }
                                          >
                                            Espagnol
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Délai sans connexion
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForCoTime}
                                        toggle={toggleDropCoTime}
                                      >
                                        <DropdownToggle
                                          id="unbelievable-override-hover"
                                          className="dropdown-boitier-custom"
                                        >
                                          {connectionTimeLimit}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"sans délais"}
                                            onClick={(e) =>
                                              setConnectionTimeLimit(
                                                e.target.value
                                              )
                                            }
                                          >
                                            sans délais
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"10 jours"}
                                            onClick={(e) =>
                                              setConnectionTimeLimit(
                                                e.target.value
                                              )
                                            }
                                          >
                                            10 jours
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"30 jours"}
                                            onClick={(e) =>
                                              setConnectionTimeLimit(
                                                e.target.value
                                              )
                                            }
                                          >
                                            30 jours
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"90 jours"}
                                            onClick={(e) =>
                                              setConnectionTimeLimit(
                                                e.target.value
                                              )
                                            }
                                          >
                                            90 jours
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"180 jours"}
                                            onClick={(e) =>
                                              setConnectionTimeLimit(
                                                e.target.value
                                              )
                                            }
                                          >
                                            180 jours
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Emplacement du boitier
                                    </div>
                                    <div className="textRow">
                                      <Autocomplete
                                        apiKey={
                                          "AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk&libraries=places"
                                        }
                                        className="heighTextArea pl-3 form-control"
                                        id="custom-input-row"
                                        onChange={(e) => {
                                          setSelectedSuggestion(false);
                                          setFullAddressValue(e.target.value);
                                        }}
                                        onPlaceSelected={(place) => {
                                          setSelectedSuggestion(true);
                                          const newAddress =
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] === "route"
                                            ).long_name +
                                            (place.address_components.find(
                                              (component) =>
                                                component.types[0] ===
                                                "street_number"
                                            )
                                              ? " " +
                                                place.address_components.find(
                                                  (component) =>
                                                    component.types[0] ===
                                                    "street_number"
                                                ).long_name
                                              : "");
                                          setAddress(newAddress);
                                          const postalCodeComponent =
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] ===
                                                "postal_code"
                                            );

                                          if (postalCodeComponent) {
                                            setPostcode(
                                              postalCodeComponent.long_name
                                            );
                                          }
                                          const newCity =
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] ===
                                                "locality"
                                            ).long_name;
                                          setCity(newCity);
                                          const newCountry =
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] === "country"
                                            ).long_name;
                                          setCountry(newCountry);

                                          // Mise à jour de l'adresse complète
                                          setFullAddressValue(
                                            `${newAddress}, ${
                                              postalCodeComponent
                                                ? postalCodeComponent.long_name
                                                : ""
                                            } ${newCity}, ${newCountry}`
                                          );
                                        }}
                                        options={{
                                          types: ["address"],
                                          componentRestrictions: {
                                          country: ["fr", "it"],
                                          },
                                        }}
                                        value={fullAddressValue}
                                        />
                                      </div>
                                      </Row>
                                    </Card>
                                    <Card className="rowContainer">
                                      <Row>
                                      <div className="titleRow">
                                        Client assigné
                                      </div>
                                      <div className="textRow">
                                        <div style={{ position: "relative" }}>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={customerName}
                                          onChange={handleCustomerInputChange}
                                          onFocus={handleInputFocus}
                                          onBlur={() =>
                                          setTimeout(
                                            () => setSuggestions([]),
                                            200
                                          )
                                          }
                                        />
                                        {suggestions.length > 0 && (
                                          <ul
                                          style={{
                                            position: "absolute",
                                            zIndex: 1,
                                            listStyle: "none",
                                            padding: 0,
                                            margin: 0,
                                            backgroundColor: "white",
                                            border: "1px solid #ddd",
                                            width: "100%",
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                          }}
                                          >
                                          {suggestions.map((client) => (
                                            <li
                                            key={client.id}
                                            onClick={() =>
                                              handleSuggestionClick(client)
                                            }
                                            style={{
                                              padding: "5px",
                                              cursor: "pointer",
                                            }}
                                            onMouseDown={(e) =>
                                              e.preventDefault()
                                            } // Empêche le onBlur de se déclencher immédiatement
                                              >
                                                {client.nom}
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </div>
                                    </div>
                                  </Row>
                                </Card>
                              </div>
                              <div className="text-center">
                                <Button type="submit" color="primary">
                                  Enregistrer
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        </form>
                      </div>
                    )}
                    {/* <ShowOldBoitierAssign
                      user_id={user_id}
                      customer_Name={customerName}
                    /> */}
                  </Col>

                  <Col lg="7" md="6">
                    <Card>
                      <ShowBoitierMap
                        markerLat={markerLat}
                        markerLng={markerLng}
                      />
                      <ShowOldBoitierComment
                        comment={comment}
                        setComment={setComment}
                        boitierId={params.id}
                        rapportId={rapportId}
                      />
                      <ShowOldBoitierImage
                        imageUrl={imageUrl}
                        comment={comment}
                      />
                      <OldBoitierLastAlert />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
