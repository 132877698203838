import react, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import customFilterMethod from "helpers/customFilterMethod";
import ReactTable from "react-table-v6";

export default function TechListIntoUserTab({importedData, navigation}) {
    const nav = useNavigate();

    const columns = [
    {
      Header: () => <div style={{ textAlign: "center" }}>ID</div>,
      accessor: (d) => d.id,
    },
    {
       Header: () => <div style={{ textAlign: "center" }}>Entreprise</div>,
       accessor: (d) => d.company,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>NOM</div>,
      accessor: (d) => `${d.name}  ${d.firstname}`,
    },
    // {
    //   Header: () => <div style={{ textAlign: "center" }}>TELEPHONE</div>,
    //   accessor: (d) => d.phone1,
    // },
    {
      Header: () => <div style={{ textAlign: "center" }}>LIEU</div>,
      accessor: (d) => d.country,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>STATUT</div>,
      accessor: (d) => d.state,
      Cell: ({ value }) => (
        <div
          style={{ color: value === 1 ? "green" : "red", textAlign: "center" }}
        >
          {value === 1 ? "actif" : "inactif"}
        </div>
      ),
    },
  ].map((col, i) => {
    col.id = `${i}`;
    return col;
  });

  const onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: (e) => {
        nav(`/showTech/${rowInfo.original.id}`); // ou show user
      },
    };
  };

 return (
      <>
      {importedData && importedData.length > 0 ? (
        <ReactTable
          filterable
          defaultFilterMethod={customFilterMethod}
          getTrProps={onRowClick}
          data={importedData}
          columns={columns}
          defaultPageSize={10}
          previousText={navigation.previousText}
          nextText={navigation.nextText}
          pageText={navigation.pageText}
          ofText={navigation.ofText}
          rowsText={navigation.rowsText}
        />
      ) : (
        <p>Aucune donnée disponible.</p>
      )}
      </>
        )
}